<template>
  <div class="w-100 active-toggle-container button_toolbar_parent_container d-flex justify-content-between align-content-center">
    <div class="d-flex justify-content-between align-items-center">
      <div class="d-flex justify-content-between align-items-center">
        <slot name="clientName"></slot>
        <slot v-if="$slots.title" name="title"></slot>
        <span v-else v-html="title"></span>
        <slot></slot>
      </div>
      <b-badge v-if="status" :variant="status.color" class="mt-1 ml-2" style="margin-left: -10px; font-size:9px!important;">{{ status.text }}</b-badge>
      <!-- <template v-slot:default></template> -->
    </div>
    <slot name="alert"></slot>

    <div class="button-container d-flex justify-content-center align-items-center">
      <div class="back-button" v-if="backButton === true && isUIConfigEnabled">
        <b-button
          class="float-right default mr-1"
          size="sm"
          @click="toLocation === undefined ? $router.go(-1) : $router.push(toLocation)"
          variant="outline-primary"
        >
          <i class="simple-icon-arrow-left"></i>
          {{ $t("controls.back-button") }}
        </b-button>
      </div>

      <!-- <div class="activeToggle text-right ml-1 mr-1" v-if="activeToggle === true">
        <b-button
          v-if="!userData.active && toActivatePermission && this.deleteButton == false"
          class="default activateButton"
          variant="outline-success"
          size="sm"
          @click="setActiveToggle(id, userData.active)"
        >
          <i class="simple-icon-power"></i>
          {{ $t("controls.activate-button") }}
        </b-button>

        <b-button v-if="userData.active && toDeactivatePermission" class="default activateButton" variant="outline-danger" size="sm" @click="setActiveToggle(id, userData.active)">
          <i v-if="!deleteButton" class="simple-icon-power"></i>
          <i v-else class="simple-icon-trash"></i>
          {{ this.deleteButton ? $t("controls.delete-button") : $t("controls.deactivate-button") }}
        </b-button>
      </div> -->

      <div class="custom-button-container text-right">
        <slot name="custom-buttons"></slot>
      </div>

      <span class="action_more_dropdown">
        <b-dropdown
          v-if="activeToggle === true || show_tpa_stamps || customButtonsAvailable"
          v-b-tooltip:hover
          :title="$t('pages.hive.work_orders.buttons.more-action')"
          class="default"
          no-caret
          size="xs"
          offset="-77"
          toggle-class="text-decoration-none"
          variant="link"
          @show="toggleDangerContent"
          @hide="toggleDangerContent"
        >
          <template slot="button-content">
            <i class="btn btn-header-light icon-button font-weight-bolder fa fa-cog" style="font-size:18px;"></i>
            <!-- <i class="font-weight-bolder simple-icon-menu" style="font-size:18px;"></i> -->
          </template>

          <b-dropdown-item v-if="show_tpa_stamps && toUpdatePermission" @click="tpa_stamp_settings_modal_show = true">
            <i class="iconsmind_icon iconsmind-Cube-Molecule2 font-weight-bold"></i>
            <span>{{ $t("controls.tpa_stamps") }}</span>
          </b-dropdown-item>

          <b-dropdown-item v-if="show_invoices_button && toUpdatePermission" :to="`/accounting/invoices/manage/${id}`">
            <i class="iconsmind_icon iconsmind-Cube-Molecule2 font-weight-bold"></i>
            <span>{{ $t("controls.invoices") }}</span>
          </b-dropdown-item>

          <b-dropdown-item
            v-if="activeToggle && !userData.active && toActivatePermission && this.deleteButton === false"
            @click="setActiveToggle(id, userData.active)"
          >
            <i class="simple-icon-power text-success"></i>
            <span class="text-success">{{ $t("controls.activate-button") }}</span>
          </b-dropdown-item>

          <template v-if="custom_buttons !== null">
            <b-dropdown-item v-for="(button, button_index) in custom_buttons" :key="button_index" @click="$emit('customButtonEvent', button.action)">
              <i v-if="!button.svg" class="fa" :class="{ [button.icon]: true }"></i>
              <component v-else :is="button.icon" style="width: 0.9rem;" />
              <span :class="button.font_color"> {{ button.title }} </span>
            </b-dropdown-item>
          </template>


           <b-dropdown-item v-if="activeToggle && userData.active && toDeactivatePermission" @click="setActiveToggle(id, userData.active)">
            <i v-if="!deleteButton" class="simple-icon-power text-danger"></i>
            <i v-else class="simple-icon-trash text-danger"></i>
            <span class="text-danger ml-1">{{ this.deleteButton ? $t("controls.delete-button") : $t("controls.disable-button") }}</span>
          </b-dropdown-item>
        </b-dropdown>
      </span>

      <div class="primary-button" v-if="setPrimaryButton">
        <b-button
          v-if="!userData.primary && toUpdatePermission"
          class="default activateButton"
          variant="outline-success"
          size="sm"
          @click="setPrimary()"
        >
          <i class="simple-icon-pin"></i>
          {{ $t("controls.set-primary-button") }}
        </b-button>
      </div>
    </div>
    <transition name="fade" mode="out-in">
      <div key="more_action_overlay" class="more_action_overlay" v-if="showDangerContent"></div>
    </transition>

    <tpa-stamp-settings-modal
      v-if="show_tpa_stamps"
      :tpa_stamps="tpa_stamps || {}"
      :id="id"
      :mainRoute="mainRoute"
      :show="tpa_stamp_settings_modal_show"
      @hideEvent="tpa_stamp_settings_modal_show = false"
      :disabled="tpaDisabled"
    />
  </div>
</template>

<script>
import TpaStampSettingsModal from "@/components/Form/ButtonToolbar_a/TpaStampSettings";
import { securedAxios } from "@/utils/securedAxios";
import { isArray } from "lodash";
import { QuickBookIcon } from "@/components/Svg";

export default {
  components: {
    TpaStampSettingsModal,
    QuickBookIcon
  },
  props: {
    toLocation: {
      type: [String, Object],
      required: false
    },
    mainRoute: {
      type: String,
      required: false
    },
    title: {
      type: String,
      required: false
    },
    id: {
      type: String,
      required: false
    },
    userDataActive: {
      type: Boolean,
      required: false
    },
    userDataPrimary: {
      type: Boolean,
      required: false
    },
    setPrimaryButton: {
      type: Boolean,
      required: false,
      default: false
    },
    activeToggle: {
      type: Boolean,
      required: false,
      default: false
    },
    activeToggleRequestMethod: {
      type: String,
      required: false,
      default: "POST"
    },
    adapter: {
      type: Function,
      required: false,
      default: () => securedAxios
    },
    backButton: {
      type: Boolean,
      required: false,
      default: true
    },
    toActivatePermission: {
      type: Boolean,
      required: false,
      default: true
    },
    toDeactivatePermission: {
      type: Boolean,
      required: false,
      default: true
    },
    toUpdatePermission: {
      type: Boolean,
      required: false,
      default: true
    },
    deleteButton: {
      type: Boolean,
      required: false,
      default: false
    },
    show_tpa_stamps: {
      type: Boolean,
      required: false,
      default: false
    },
    tpa_stamps: {
      type: [Object, null, undefined],
      required: false
    },
    tpaDisabled: {
      type: Boolean,
      required: false,
      default: false
    },
    status: {
      type: [Object, null, undefined],
      required: false
    },
    show_invoices_button: {
      type: Boolean,
      required: false,
      default: false
    },
    invoices_button_title: {
      type: [String, null],
      required: false,
      default: null
    },
    custom_buttons: {
      type: [Array, null],
      required: false
    }
  },
  data() {
    return {
      force: "false",
      userData: {
        active: null,
        primary: null
      },
      tpa_stamp_settings_modal_show: false,
      showDangerContent: false
    };
  },
  created() {
    this.userData.active = this.userDataActive;
    this.userData.primary = this.userDataPrimary;
  },
  computed: {
    isUIConfigEnabled() {
      return this.$store.state.UIConfig.buttons.backButtonEnabled;
    },
    customButtonsAvailable() {
      return this?.custom_buttons?.length > 0 || false;
    }
  },
  methods: {
    toggleDangerContent() {
      this.showDangerContent = !this.showDangerContent;
    },
    setActiveToggle(id, active) {
      // if (this.deleteButton) {
      this.confirmAlert(this.$t("alert-box.confirm.want-to-proceed-virgin"), "continue").then(result => {
        if (result.value) {
          this.confirmAlert(this.$t("alert-box.confirm.want-to-proceed-virgin"), "continue").then(result => {
            if (result.value) {
              this.activateDeactivateToggle(id, active);
            }
          });
        }
      });
      // } else {
      // this.activateDeactivateToggle(id, active);
      // }
    },
    activateDeactivateToggle(id, active) {
      this.$store
        .dispatch("activeToggle", {
          adapter: this.adapter,
          endpoint: this.mainRoute,
          id: id,
          items: [],
          method: this.activeToggleRequestMethod,
          type: active === true ? "/deactivate" : "/activate",
          toggleStatus: !active,
          force: this.force
        })
        .then(response => {
          // If Returning type is error
          if (response !== undefined) {
            if (response.type === "error") {
              // If Record has relations, we cannot deactivate. Ask Do you want to force it?
              let messages = "";
              if (response.response.hasOwnProperty("data") && isArray(response.response.data)) {
                messages = this.$t("alert-box.confirm.some-warnings-text") + response.response.data.join("<br>");
              }
              //Give Confirm Message
              this.confirmAlert(messages, "deactivate").then(response => {
                // If Confirms
                if (response.value) {
                  this.force = "true";
                  this.activateDeactivateToggle(this.id, this.userData.active);
                }
              });
            } else {
              // in case of all situation we need to set force to false (String)
              this.userData.active = !this.userData.active;
              this.force = "false";
              if (this.deleteButton) {
                // To make it equlize initialData and userData for passing router guards
                this.$emit("deleteEvent", true);
                this.$router.go(-1);
                // .push(localStorage.redirect_inventory_product_url || this.mainRoute);
              }
              // Nofity User
              const notificationMessage = this.userData.active ? "activate" : "deactivate";
              this.createSuccessNotification(notificationMessage)
            }
          }
        });
    },
    setPrimary() {
      securedAxios.patch(this.mainRoute + this.id + "/set_primary").then(response => {
        if (response.status == 200) {
          this.userData.primary = true;
          // Nofity User
          this.$notify("success filled", this.$t("notification.title.success"), this.$t("notification.message.update.primary"), {
            duration: this.$store.state.notificationConfig.duration,
            permanent: this.$store.state.notificationConfig.permanent
          });
        } else {
          // Nofity User
          this.$notify("error filled", this.$t("notification.title.error"), this.$t("notification.message.update.error"), {
            duration: this.$store.state.notificationConfig.duration,
            permanent: this.$store.state.notificationConfig.permanent
          });
        }
      });
    }
  }
};
</script>

<style>
.button_toolbar_parent_container > .title-container {
  padding-left: 9px;
}

.button_toolbar_parent_container .action_more_dropdown .dropdown-toggle {
  margin-right: -10px;
  padding-right: 0;
}
.button_toolbar_parent_container .action_more_dropdown .dropdown-toggle::after {
  border: none;
}

.button_toolbar_parent_container .action_more_dropdown .b-dropdown {
  z-index: 999 !important;
}
.button_toolbar_parent_container .action_more_dropdown .b-dropdown .dropdown-menu.show {
  top: 15px !important;
  left: -19px !important;
  z-index: 999;
  padding-top: 0px;
  padding-bottom: 0px;
}

.button_toolbar_parent_container > .more_action_overlay {
  position: fixed;
  width: 100vw !important;
  height: 100vh !important;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 998;
  transition: all 0s;
}
</style>
