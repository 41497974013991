import axios from "axios";
import { b2bSecureUserApiUrl } from '@/constants/config'
import router from "@/router";

const b2bSecureUserAxios = axios.create({
  baseURL: b2bSecureUserApiUrl,
  withCredentials: false,
  headers: {
    "Content-Type": "application/json"
  }
});

b2bSecureUserAxios.interceptors.request.use(config => {
  const regex = /((?<p1>([0-9]+)),(?<p2>([0-9]+)))/gim;
  if (config.data && !(config.data instanceof FormData)) {
    let config_data = JSON.stringify(config.data);

    config_data.match(regex)?.forEach(match => {
      config_data = config_data.replace(match, `${match.split(",")[0]}.${match.split(",")[1]}`);
    });

    config.data = JSON.parse(config_data);
  }

  const method = config.method.toUpperCase();
  if (method !== "OPTIONS") {
    config.headers = {
      ...config.headers,
      tknk: localStorage.getItem("token")
    };
  }
  return config;
});

b2bSecureUserAxios.interceptors.response.use(null, error => {
  if (
    error.response &&
    error.response.config &&
    error.response.status === 401 /// XXXXX We will fill this
  ) {
    delete localStorage.token
    delete localStorage.user

    return router.push({ name: 'LoginPage' })
  } else {
    return Promise.reject(error);
  }
});

export { b2bSecureUserAxios };
