export default [
  {
    name: 'PrepressFileUploader',
    path: '/prepress/file-uploader',
    component: () => import('@/views/app/pages/prepress/uploader'),
    children: []
  },
  {
    name: 'PrepressJobListPage',
    path: '/prepress/jobs',
    meta: { listPage: 'jobs', listPageTitleKey: 'jobs' },
    component: () => import('@/views/app/pages/prepress/jobs/JobListPage')
  },
  {
    name: 'PrepressLayoutSearchPage',
    path: '/prepress/layouts',
    meta: { listPage: 'layouts', listPageTitleKey: 'layouts' },
    component: () => import('@/views/app/pages/prepress/layouts/Search.vue')
  },
  {
    name: "PrepressLayoutsDetailPage",
    path: "/prepress/layouts/:work_order_id",
    component: () => import("@/views/app/pages/prepress/layouts/LayoutPages")
  },
]
